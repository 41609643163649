.base-sectionItem {
  padding: 32px 0;
}
.base-sectionItem__inner {
  width: 100%;
  max-width: 1280px;
  padding: 0 32px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.base-sectionItem__inner.nofilter {
  justify-content: center;
}
.base-sectionItem__inner__filter__flg {
  display: none;
}
.base-sectionItem__inner__filter {
  display: none;
}
.base-sectionItem__inner__filter__bg {
  display: none;
}
.base-sectionItem__inner__side {
  width: 260px;
  position: sticky;
  top: 16px;
}
.base-sectionItem__inner__main {
  width: calc(100% - 260px - 32px);
}
.base-sectionItem__inner__main__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}
.base-sectionItem__inner__main__header__select {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: auto;
}
.base-sectionItem__inner__main__header__select p {
  font-weight: bold;
}
.base-sectionItem__inner__main__header__select label {
  width: 200px;
  height: 40px;
  border: 1px solid #aaa;
  border-radius: 4px;
  overflow: hidden;
  display: block;
  position: relative;
}
.base-sectionItem__inner__main__header__select label::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #333 transparent transparent transparent;
  margin-left: 8px;
  position: absolute;
  top: calc(50% - 2px);
  right: 8px;
}
.base-sectionItem__inner__main__header__select select {
  width: 100%;
  height: 100%;
  background: #fff;
  background: var(--theme-background-color);
  padding: 0 24px 0 8px;
}
.base-sectionItem__inner__main__body {
}
.base-sectionItem__inner__main__body__list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.base-sectionItem__inner__main__body__list__item {
  width: calc((100% - 32px) / 3);
  margin-bottom: 32px;
}
.base-sectionItem__inner__main__body__list__item:not(:nth-child(3n)) {
  margin-right: 16px;
}
.base-sectionItem__inner__main__body__btn {
  width: 300px;
  height: 48px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 4px;
  color: #fff;
  margin: 32px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-card-filter {
  width: 100%;
  max-width: 480px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  background: var(--theme-content-section-background-color);
  overflow: hidden;
}
.c-card-filter__headline {
  width: 100%;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-card-filter__box {
  padding: 8px;
  position: relative;
}
.c-card-filter__box + .c-card-filter__box {
  border-top: 1px solid #ddd;
}
.c-card-filter__box__ttl {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
  color: var(--theme-primary-color);
}
.c-card-filter__box__list {
  position: relative;
}
.c-card-filter__box__list.is-close {
  max-height: 240px;
  overflow: hidden;
}
.c-card-filter__box__list__item {
}
.c-card-filter__box__list__item label {
  width: 100%;
  min-height: 32px;
  padding: 6px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.c-card-filter__box__list__item label > input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.c-card-filter__box__list__item label > div {
  width: 16px;
  height: 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  margin-right: 8px;
}
.c-card-filter__box__list__item label > div span {
  width: 100%;
  height: 100%;
  position: relative;
  display: none;
}
.c-card-filter__box__list__item label > div span::after {
  content: '';
  display: block;
  width: 4px;
  height: 9px;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  transform: rotate(45deg);
  position: absolute;
  top: -1px;
  left: 3px;
}
.c-card-filter__box__list__item label > input:checked + div span {
  display: block;
}
.c-card-filter__box__list__item label > p {
  width: calc(100% - 16px - 4px);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.c-card-filter__box__more {
  width: 100%;
  height: 48px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  left: 0;
  bottom: 8px;
}
.c-card-filter__box__more.is-close {
  position: absolute;
}
.c-card-filter__box__more.is-open {
  position: relative;
}
.c-card-filter__box__more__btn {
  width: 160px;
  height: 32px;
  font-size: 14px;
  font-weight: bold;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-card-filter__btn {
  width: calc(100% - 32px);
  height: 56px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  color: #fff;
  margin: 32px auto;
  justify-content: center;
  align-items: center;
  display: none;
}

.form-calendar-label {
  padding-bottom: 5px !important;
}
.form-calendar {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #000;
  border-radius: 4px;
  display: block;
  position: relative;
  margin-bottom: 8px;
  cursor: pointer;
}
.form-calendar input {
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding: 4px 0 4px 8px; 
  background: #fff;
}
.form-calendar input::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  width: 40px;
  height: 40px;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.form-calendar img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.form-calendar-input-container {
  max-width: 240px;
}

@media screen and (max-width: 768px) {
  .base-sectionItem__inner {
    padding: 0;
    flex-direction: column;
  }
  .base-sectionItem__inner__main {
    width: calc(100% - 240px - 16px);
  }
  .base-sectionItem__inner__filter {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    margin-left: auto;
    margin-right: 16px;
    margin-bottom: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 64px;
    z-index: 1;
  }
  .base-sectionItem__inner__filter img {
    width: 24px;
    height: 24px;
  }
  .base-sectionItem__inner__filter__bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 101;
    display: none;
  }
  .base-sectionItem__inner__filter__flg:checked ~ .base-sectionItem__inner__side {
    transform: translateX(0);
  }
  .base-sectionItem__inner__filter__flg:checked ~ .base-sectionItem__inner__filter__bg {
    display: block;
  }
  .base-sectionItem__inner__side {
    width: 300px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 102;
    transform: translateX(100%);
    transition: transform 0.4s ease;
  }
  .base-sectionItem__inner__main {
    width: 100%;
    padding: 0 16px;
  }
  .base-sectionItem__inner__main__header {
    flex-direction: column;
    align-items: flex-start;
  }
  .base-sectionItem__inner__main__header__select {
    width: 100%;
    margin-top: 16px;
  }
  .base-sectionItem__inner__main__header__select p {
    width: 64px;
  }
  .base-sectionItem__inner__main__header__select label {
    width: calc(100% - 64px);
  }
  .c-card-filter {
    width: 300px;
    height: 100vh;
    border-radius: 0;
    border: 0;
    padding-bottom: 64px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .c-card-filter__box {
    padding: 16px;
  }
  .c-card-filter__box:last-of-type {
    border-bottom: 1px solid #ddd;
  }
  .c-card-filter__btn {
    display: flex;
  }
}
@media screen and (max-width: 600px) {
  .base-sectionItem__inner__main__body__list__item {
    width: calc((100% - 16px) / 2);
  }
  .base-sectionItem__inner__main__body__list__item {
    width: 100%;
  }
  .base-sectionItem__inner__main__body__list__item:not(:nth-child(3n)) {
    margin-right: 0;
  }
  .base-sectionItem__inner__main__body__list__item:not(:nth-child(3n)) {
    margin-right: 0;
  }
  .base-sectionItem__inner__main__body__list__item:nth-child(odd) {
    margin-right: 0;
  }
}
